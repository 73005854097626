import { Component, createRef } from 'react'
import type { TextFieldProps } from '@material-ui/core'
import validatePresence from '../lib/validatePresence'
import validateEquals from '../lib/validateEquals'
import StyledTextField from './StyledTextField'

type Props = {
  // Identifier used to inform the user of errors on the confirmation field. e.g. password confirmation
  FormHelperTextProps: TextFieldProps['FormHelperTextProps']
  helperColor?: string
  helperText: TextFieldProps['helperText']
  // Plural of the object being checked for equality. e.g. passwords
  humanIdentifier: string
  InputLabelProps?: TextFieldProps['InputLabelProps']
  inputProps?: TextFieldProps['inputProps']
  InputProps?: TextFieldProps['InputProps']
  label?: string
  multiSamlEnabled?: boolean
  name?: string
  onValidate: (error: string | null) => void
  originalFieldPlural: string
  type: string
}

interface State {
  confirmationValue: string | null
  originalValue: string | null
}

export default class ConfirmationField extends Component<Props, State> {
  textFieldRef: TextFieldProps['ref']

  constructor(props: Props) {
    super(props)
    this.textFieldRef = createRef()
    this.state = {
      confirmationValue: null,
      originalValue: null,
    }
  }

  errors = (shouldValidatePresence: boolean): string | null =>
    (shouldValidatePresence &&
      validatePresence(
        this.props.humanIdentifier,
        this.state.confirmationValue,
      )) ||
    (this.state.originalValue &&
      this.state.confirmationValue &&
      validateEquals(
        this.props.originalFieldPlural,
        this.state.originalValue,
        this.state.confirmationValue,
      ))

  validate = (shouldValidatePresence = false): void => {
    this.props.onValidate(this.errors(shouldValidatePresence))
  }

  handleOriginalFieldChange: TextFieldProps['onChange'] = (event) => {
    this.setState(
      {
        originalValue: event.target.value,
      },
      this.validate,
    )
  }

  handleChange: TextFieldProps['onChange'] = (event) => {
    this.setState(
      {
        confirmationValue: event.target.value,
      },
      this.validate,
    )
  }

  render(): JSX.Element {
    const {
      FormHelperTextProps,
      helperColor,
      helperText,
      InputLabelProps,
      inputProps,
      InputProps,
      label,
      multiSamlEnabled,
      name,
      type,
    } = this.props

    return (
      <StyledTextField
        FormHelperTextProps={FormHelperTextProps}
        helperColor={helperColor}
        helperText={helperText}
        InputLabelProps={InputLabelProps}
        inputProps={inputProps}
        InputProps={InputProps}
        label={label}
        multiSamlEnabled={multiSamlEnabled}
        name={name}
        onBlur={this.handleChange}
        onChange={this.handleChange}
        textFieldRef={this.textFieldRef}
        type={type}
      />
    )
  }
}
